@import './reset';
@import '~simple-keyboard/build/css/index.css';

$primary-color: #0065AD;
$secondary-color: #45BBF6;
$third-color: #040470;

html, body {
  position: relative;
  margin: 0;
  padding: 0;
  width: 1920px;
  height: 1080px;
  font-family: 'Poppins';
  color: #FFF;
  user-select: none;
}

a {
  text-decoration: none;
  font-size: 50px;
}

h1 {
  font-size: 50px;
}

.btn {
  width: 384px;
  height: 95px;
  background-color: $secondary-color;
  color: $third-color;
  font-size: 44px;
  font-weight: 600;
  border: none;
  box-shadow: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus, &:visited, &:active {
    outline: none;
  }
  &.inactive {
    background-color: #c6c6c6;
  }
}

.main-wrapper {
  // background-color: $primary-color;
  background-image: url('./assets/images/bg-static-2.jpg');
  background-size: 110% 110%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &.home {
    background-image: none;
    background-color: $primary-color;
    .home-image {
      width: 1245px;
      height: 1080px;
      background-image: url('./assets/images/home-image.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .home-text {
      width: 675px;
      height: 1080px;
      background-image: url('./assets/images/home-right-2.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 282px;
        margin-bottom: 90px;
      }
      h1 {
        font-size: 60px;
        font-weight: 600;
        line-height: 82px;
        text-align: center;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 29px;
        line-height: 37px;
        text-align: center;
        margin-bottom: 44px;
        transition: transform 0.2s ease-in-out;
      }
    }
  }
}

.cards-wrapper {
  display: flex;
  width: 798px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 174px;
  height: 100px;
  perspective: 500px;
  margin-bottom: 30px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  &:nth-child(n + 4) {
    margin-right: 0;
  }
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.2s;
    transform-style: preserve-3d;
  }
  &.selected, &.rotaded, &.matched {
    .content {
      transform: rotateY(180deg);
      transition: transform 0.2s;
    }
  }
  &.selected, &.matched {
    pointer-events: none;
  }
  .front, .back {
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
    text-align: center;
    border-radius: 10px;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }
  }
  .front {
    background-image: url('./assets/images/rewers.png');
    background-size: contain;
  }
  .back {
    background-color: #FFF;
    transform: rotateY(180deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
}

.timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid $secondary-color;
  border-radius: 50%;
  width: 175px;
  height: 175px;
  position: absolute;
  top: 454px;
  left: 232px;
  h1, h3 {
    color: #FFF;
    font-size: 43px;
    line-height: 55px;
  }
  h3 {
    color: #FFF;
    font-size: 29px;
  }
}

.back-to-home {
  position: absolute;
  top: 60px;
  left: 1741px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 46px;
    height: 46px;
    margin-bottom: 9px;
  }
  h3 {
    font-size: 16px;
    line-height: 23px;
  }
}

.header-logo {
  position: absolute;
  top: 60px;
  left: 101px;
  width: 202px;
  height: 86px;
}

.main-wrapper-nick {
  flex-direction: column;
  // justify-content: space-between;
  height: 1080px;
  position: relative;
  .keyboard-wrapper {
    // position: fixed;
    left: 0;
    bottom: 0;
    width: 1920px;
    height: 365px;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    .simple-keyboard {
      width: 1000px;
      color: #000;
      &.hg-theme-default {
        background-color: #fff;
        .hg-button {
          height: 58px;
        } 
      }
      
    }
  }
}

.nick-wrapper {
  height: 715px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 29px;
    text-align: center;
    margin-bottom: 40px;
  }
  .nick-input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    input {
      position: relative;
      display: flex;
      justify-content: center;
      text-align: center;
      border: none;
      background-color: transparent;
      color: #FFF;
      font-size: 33px;
      width: 48px;
      line-height: 37px;
      margin-bottom: 12px;
      margin-right: 27px;
      padding-bottom: 19px;
      border-bottom: 4px solid $secondary-color;
      text-transform: uppercase;
      &:last-child {
        margin-right: 0;
      }
      &:focus {
        outline: none;
      }
    }
    .input-border {
      position: relative;
      width: 346px;
      height: 4px;
      background-image: url('./assets/images/nick-border.svg');
    }
  }
}

.main-wrapper-end {
  flex-direction: column;
}

.results-table {
  // width: 400px;
  margin-top: 42px;
  td {
    font-size: 29px;
    padding: 8px 0;
  }
  .place {
    width: 64px;
  }
  .nick {
    width: 144px;
  }
  .time {
    width: 124px;
  }
  tr.current {
    td {
      font-weight: 700;
    }
  }
}

.buttons {
  display: flex;
}

.back-to-home-end {
  position: relative;
  margin: 50px;
  top: auto;
  left: auto;
}